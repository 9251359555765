<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card no-body>
				<b-card-header>
					<b-row>
						<b-col md="4" lg="4">
							<h4>{{ rendTitle }}</h4>
						</b-col>
						<b-col md="8" lg="8">
							<b-row :class="`${$screen.width >= 992 ? 'pull-right pr-2' : 'pull-right'}`">
								<b-col cols="12">
									<router-link to="/tasks" tag="b-button" class="btn btn-warning mr-2">
										{{ FormMSG(24, 'Back to tasks') }}
										&nbsp;<i class="icon-action-undo"></i>
									</router-link>
									<b-button @click="backToList" variant="success" size="md" class="btn btn-primary btn-md">
										{{ this.FormMSG(2, 'Back to list') }}&nbsp;<i class="icon-action-undo"></i>
									</b-button>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-card-header>
				<b-card-body>
					<fieldset class="scheduler-border">
						<legend class="scheduler-border">{{ this.FormMSG(17, 'Form') }}</legend>
						<b-form @submit.prevent="onSubmit">
							<b-row>
								<b-col md="9" lg="9">
									<b-form-group :label="FormMSG(3, 'Name')" label-for="name" :label-cols="3" :horizontal="true">
										<b-form-input v-model="taskType.name" type="text" required :placeholder="FormMSG(4, 'Name...')" />
									</b-form-group>
									<b-form-group :label="FormMSG(25, 'Description')" label-for="description" :label-cols="3" :horizontal="true">
										<b-form-textarea
											v-model="taskType.description"
											type="textarea"
											rows="12"
											:placeholder="FormMSG(26, 'Description...')"
											autocomplete="off"
										/>
									</b-form-group>
									<b-form-group :label="FormMSG(7, 'Duration')" label-for="duration" :label-cols="3" :horizontal="true">
										<div class="d-flex row">
											<div class="col-6">
												<div class="d-flex row">
													<div class="col-12">
														<div class="d-flex flex-row">
															<b-form-timepicker
																v-model="taskType.duration"
																type="text"
																required
																:placeholder="FormMSG(8, 'Duration...')"
															/>
															<div class="ml-2 mt-2 task-label text-success font-weight-bold">
																{{ FormMSG(22, 'Hours/d') }}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="col-6">
												<div class="d-flex row">
													<div class="col-12">
														<div class="d-flex flex-row">
															<b-form-input
																v-model="taskType.dayDuration"
																type="number"
																step="1"
																min="0"
																:placeholder="FormMSG(23, 'Days...')"
															></b-form-input>
															<div class="ml-2 mt-2 task-label text-success font-weight-bold">
																{{ FormMSG(21, 'Days') }}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</b-form-group>
									<b-form-group :label="FormMSG(9, 'Priority')" label-for="priority" :label-cols="3" :horizontal="true">
										<b-form-select :options="priorities" v-model="taskType.priority"></b-form-select>
									</b-form-group>
									<b-row>
										<b-col>
											<b-form-group>
												<b-row>
													<div class="col-3">
														<b-img v-if="taskType.picture !== ''" :src="getPicture(taskType.picture)" width="32" height="32" />
														<b-img v-else blank-color="#D3DEDC" width="32" height="32" rounded="circle" blank />
													</div>
													<div class="col-9">
														<b-button
															variant="outline-secondary"
															size="md"
															@click="startCapture"
															class="btn-block mb-3"
															v-if="!taskType.isSystem"
														>
															<span>
																<i class="icon-camera" />
																{{ FormMSG(5, 'Change your picture') }}
															</span>
														</b-button>
													</div>
												</b-row>
											</b-form-group>
										</b-col>
									</b-row>
								</b-col>
								<b-col md="3" lg="3">
									<b-form-group>
										<b-button type="submit" variant="primary" size="md" block>
											<span>{{ FormMSG(13, 'Save') }}</span>
										</b-button>
									</b-form-group>
								</b-col>
							</b-row>
						</b-form>
					</fieldset>
					<b-row>
						<b-col cols="12">
							<b-card no-body header-tag="header" footer-tag="footer">
								<template #header>
									<div class="d-flex flex-row">
										<div class="mr-2 text-success task-icon-card-header">
											<i class="fa fa-cart-arrow-down" aria-hidden="true"></i>
										</div>
										<div class="text-muted task-label-card-header">
											<h6 class="mb-0 font-weight-bold">{{ FormMSG(16, 'Materials associated') }}</h6>
										</div>
									</div>
								</template>
								<b-card-body>
									<task-type-material-list ref="task-type-material-list" @task-type-material:reloadData="reloadMaterialList" />
								</b-card-body>
								<template #footer>
									<b-row>
										<b-col md="9" lg="9"></b-col>
										<b-col md="3" lg="3">
											<b-form-group class="my-0">
												<b-button
													type="button"
													variant="outline-primary"
													size="md"
													class="py-2 px-4 tags-assigned"
													@click="addNewMaterial"
													block
												>
													<span>{{ FormMSG(18, 'Add') }}</span>
												</b-button>
											</b-form-group>
										</b-col>
									</b-row>
								</template>
							</b-card>
						</b-col>
					</b-row>
					<task-type-material-modal v-model="isOpenMaterialModal" :material="material" @task-type-material-modal:cancel="onCancelMaterialModal" />
				</b-card-body>
				<NewCapture
					:key="refreshTokenCaptureItem"
					v-model="capturePict"
					parent-type="task_type"
					:parent-id="taskType.id"
					auto-crop-capture
					:cam-mode-first="false"
					only-one-crop-mode
					@change="handleImgsTaked"
				/>
				<!--<b-modal
										header-class="header-class-modal-doc-package"
                    :title="FormMSG(11, 'Picture')"
                    class="modal-success"
                    v-model="openShowRoomPicModal"
                    @ok="openShowRoomPicModal = false"
                    ok-variant="success"
                    ok-only
                >
                    <ImageShowRoom :hideComment="true" :images="imagesViewerList" />
                </b-modal>-->
			</b-card>
		</transition>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import meterHelperMixin from '@/components/LocationService/meterHelperMixin';
import { store } from '@/store';
import NewCapture from '@/components/CaptureModule/Main';
import { addUpdateTaskType, getTaskType, updatedTaskTypeItem } from '@/cruds/tasktypes.crud';
import TaskTypeMaterialModal from '@/components/Modals/TaskTypeMaterialModal';
import TaskTypeMaterialList from '@/components/TaskType/Material/List';

export default {
	name: 'TaskTypeDetails',
	components: {
		NewCapture,
		TaskTypeMaterialModal,
		TaskTypeMaterialList
	},
	mixins: [languageMessages, meterHelperMixin],
	computed: {
		rendTitle() {
			return this.FormMSG(1, 'Update Task type');
		},
		priorities() {
			return this.FormMenu(1123);
		}
	},
	data() {
		return {
			erreur: {},
			warning: '',
			capturePict: false,
			refreshTokenCaptureItem: 0,
			taskType: {},
			isOpenMaterialModal: false,
			material: {}
		};
	},
	async mounted() {
		const { id } = this.$route.params;
		this.taskType = await getTaskType(id);
		this.taskType.duration = this.splitDuration(this.taskType.duration);
	},
	methods: {
		backToList() {
			this.$router.push('/task-types');
		},
		async handleImgsTaked(imgs) {
			const retStr = imgs[0];
			this.taskType.picture = retStr;
			store.state.imageCropped = '';
			await addUpdateTaskType(this.taskType.id, {
				picture: this.taskType.picture
			});
			this.refreshTokenCaptureItem = this.refreshTokenCaptureItem + 1;
			this.getPicture(this.taskType.picture);
		},
		startCapture: function () {
			// initialise global store variable and open dialog to capture and crop
			//console.log("instartcapture");
			store.state.capturedImage = '';
			store.state.imageCropped = '';
			this.capturePict = true;
		},
		prepareDuration(duration) {
			return `0001-01-01T${duration}Z`;
		},
		splitDuration(duration) {
			const date = new Date(duration);
			return `${this.addZero(date.getUTCHours())}:${this.addZero(date.getUTCMinutes())}:00`;
		},
		addZero(time) {
			return time > 10 ? time : '0' + time;
		},
		async onSubmit() {
			this.taskType.duration = this.prepareDuration(this.taskType.duration);
			this.taskType.priority = parseInt(this.taskType.priority, 10);
			this.taskType.dayDuration = parseInt(this.taskType.dayDuration, 10);
			await addUpdateTaskType(this.taskType.id, this.taskType);

			this.taskType.duration = this.splitDuration(this.taskType.duration);
			this.$emit('task-type:updated', this.taskType);

			this.$toast({
				message: this.FormMSG(14, 'Task type updated successfully'),
				title: this.FormMSG(15, 'Success'),
				variant: 'success'
			});
		},
		async addNewMaterial() {
			const material = await updatedTaskTypeItem(0, {
				name: 'Default material',
				taskTypeId: parseInt(this.taskType.id, 10)
			});

			this.$refs['task-type-material-list'].reloadData(this.taskType.id);

			this.material = material;
			setTimeout(() => {
				this.isOpenMaterialModal = true;
			}, 150);

			this.$toast({
				message: this.FormMSG(19, 'Material added successfully'),
				title: this.FormMSG(20, 'Success'),
				variant: 'success'
			});
		},
		async reloadMaterialList(taskTypeId) {
			this.$refs['task-type-material-list'].reloadData(taskTypeId);
		},
		getPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		async onCancelMaterialModal(payload) {
			this.isOpenMaterialModal = payload;
			this.$refs['task-type-material-list'].reloadData(this.taskType.id);
		}
	}
};
</script>
