import { isNil, makeID, objReducer } from '@/shared/utils';
import { Camera, CameraResultType } from '@capacitor/camera';
import { getFileExtension } from '@/shared/helpers';
import { store } from '@/store';

export default {
	name: 'MeterHelperMixin',
	data() {
		return {
			isStartMobilePwaCaptureLoading: false
		};
	},
	props: {
		editData: {
			type: Object,
			required: false,
			default: null
		},
		parentId: {
			type: [Number, String],
			required: false,
			default: ''
		}
	},
	computed: {
		isEditMode() {
			return !isNil(this.editData);
		},

		currMeterKeys() {
			return Object.keys(this.payload);
		}
	},
	async created() {
		await this.initEdit();
	},
	methods: {
		/**
		 * @param {*} any
		 * @return {Boolean}
		 */
		has(any) {
			return !isNil(any);
		},

		/**
		 * @param {String} name
		 * @return {String}
		 */
		rendRef(name) {
			return `_${name}_${makeID(5)}`;
		},

		initEdit() {
			if (!this.isEditMode) return;
			const s = objReducer(this.currMeterKeys, this.editData);
			this.payload = Object.assign(this.payload, s);
		},
		save(key, img) {
			this.$emit('save', { img, key });
		},

		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true
				}
			});
		},

		/**
		 * @param {String} functionName
		 */
		async startMobilePwaCapture(functionName) {
			this.isStartMobilePwaCaptureLoading = true;

			const photo = await Camera.getPhoto({
				resultType: CameraResultType.Uri,
				allowEditing: true,
				quality: 100
			});

			const blob = await fetch(photo.webPath).then((r) => r.blob());
			let formData = new FormData();
			formData.append('uploadimage', blob);

			// const { parentType, parentSubType, parentId } = this.captureOptions
			// if (!isNil(parentType)) formData.append('parentType', parentType)
			// if (!isNil(parentSubType)) formData.append('parentSubType', parentSubType)
			if (!isNil(this.parentId)) formData.append('parentId', this.parentId);

			try {
				const pic = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this[functionName]([pic]);
			} catch (e) {
				console.error({ e });
			}

			this.isStartMobilePwaCaptureLoading = false;
		}
	}
};
